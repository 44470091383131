import { defineStore } from 'pinia'

export type Category = {
  id: number,
  name: string,
  category_id: number,
  slug: string,
  description: string,
}

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    categories: [] as Array<{ id: number; name: string; slug: string; description: string }>,
  }),

  actions: {
    async fetchCategories() {
      try {
        const config = useRuntimeConfig();
        const response = await $fetch<{ data: Array<{ id: number; name: string; slug: string; description: string }> }>(
          `${config.public.sanctum.baseUrl}/api/categories`
        )
        this.categories = response.data
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    },
  },
})
